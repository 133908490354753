<template>
  <div class="w100p" >
    <div class="flex-a-b-c h70 pr20 mb20">
      <div></div>
      <div class="d-flex">
        <el-button size="small"
                     type="primary"
                     @click="initialize_tap">
            恢复默认
          </el-button>
          <el-button size="small"
                     type="primary"
                     @click="save_template">
            保存
          </el-button>
      </div>
    </div>
    <div class="w100p d-flex">
    
    <!-- left -->
    <div class="w50p flex-c-c pt30 pb30">
        <div class="phoneClass borderboxd-flex flex-column">
          <div class="d-flex oyauto imgScroll" style="height:650px;width:100%;">
            <div class="felx1 text-align">
              <p class="fs10 " style="line-height: 40px;">商城</p>
              <!-- 商城模板 -->
              <div class="d-flex flex-column">
                <div class="" 
                  v-for="(moduleList,index) in ecommerceList" 
                  :key="index">
                    
                  <!-- 搜索 -->
                  <div class="borderbox pl5 pr5 mb20 posre" v-if="moduleList.type == 'search'" @click="selected_tap(moduleList.type)">
                    <img  :src="moduleList.img" class="w100p" >
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      
                    </div>
                  </div>
                   <!-- tab -->
                    <div class="w100p borderbox pl10 pr10 d-grid20p "  v-if="moduleList.type == 'tab'" @click="selected_tap(moduleList.type)">
                        <div class="d-flex align-items-center flex-column bgfff mb10 br10"  v-for="(item,k) in moduleList.list" :key="k">                         
                            <img :src="item.img" class="h40 w40">
                            <div class="bgfff fs7 pb5 brbottom10" :style="{color:item.color,background:item.bg}">{{item.title}}</div>
                        </div>
                        <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                        
                      </div>
                    </div>
                    <!-- 推荐模块 -->
                    <div class="d-flex justify-content-between pl10 pr10 mt18 posre" v-if="moduleList.type == 'diy1'" @click="selected_tap(moduleList.type)">
                      <div class="d-flex recommend ">
                        <div class="posre"  v-for="(item,k) in moduleList.list" :key='k'>
                          <div class="w63 h18 fs6 cfff br3 mb10" v-if="item.title" :style="{background:item.bg}">{{item.title}}</div>
                          <div v-else class="w63 h18 mb10" ></div>
                          <img :src="item.img" class="h77" :class="(k == 0 || k== 1) ? 'w63 mr9' : 'w152'" >
                          <div class="diy1Bottom" v-if="item.text">
                            {{item.text}}
                          </div>
                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      </div>
                    </div>

                    <div class="pl10 mt18 w340 ohidden posre" v-if="moduleList.type == 'boutique'" @click="selected_tap(moduleList.type)">
                      <div class="d-flex justify-content-between mb14">
                        <div class="pl10 pr10 flex-a-b-c w100p">
                          <div class="d-flex flex-a-c">
                            <span class="bgbule d-block" style="width:4px;height:18px"></span>
                            <p class="fs9 fw600 lineheight1   ml10">{{moduleList.title}}</p>
                          </div>
                          <div class="cblue fs7" style="padding:4px 10px;border-radius:20px;border: 1px solid #51CBCD;">
                            更多 More+
                          </div>
                        </div>
                      </div>
                      <div class="d-flex  text-align-left mb17 ">
                        <div class="flex1 d-flex flex-wrap-y">
                          <div class="d-flex flex-column" :class="k == 2 ? '' : 'mr10' " v-for="(item,k) in moduleList.list" :key="k">
                              <img :src="item.goodPhoto" style="width:98px;height:98px" class="br10 ohidden">
                              <div class="d-flex flex-column">
                                <p class="fs7 fw600 lineheight1 pt15 pb10 w98 text-overflow">{{item.goodsName}}</p>
                                <div class="d-flex pb10">
                                  <div class="fs9 corange lineheight1 w98 text-overflow">￥{{(item.price/100) || 0}}</div> 
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                        
                      </div>
                    </div>
                    

                    <div class="mr10 ml10 pb20 posre" v-if="moduleList.type == 'boutique1'" @click="selected_tap(moduleList.type)">
                      <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                        {{moduleList.title}}
                        <img src="../../../static/ecommerce/img_tag_cardcase.png" class="w100 h10 posab choicenessBgImg">
                      </div>
                      
                      <div class="choicenessBox mb10" v-for="(item,k) in moduleList.list" :key="k">
                        <img :src="item.goodPhoto" class="w90 h90 mr10">
                        <div class="flex1 d-flex justify-content-between text-align-left  flex-column">
                          <div>
                            <p class="fs8 fwbold">{{item.goodsName}}</p>
                          </div>
                          <div class="fs8 corange lineheight1 mr5">￥{{(item.price/100)}}</div>
                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                        
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>
          
          <div class="d-flex flex1 bgfff justify-content-between">
            <div style="width:52px;height:100%" 
                 class="flex-c-c flex-column"
                 v-for="(item,index) in tabList" 
                 :key="index">
                <img :src="index == 2 ? item.selectedIconPath : item.iconPath " class="w40 h40">
                <p class="fs6">{{item.text}}</p>
            </div>
          </div>
        </div>
    </div>
    <!-- right -->
    <div class="w50p posre d-flex oyauto oscrollbar">
      <div class="flex1 h550 pt20 pl20 pb20 w100p">
        <div class="mt20" v-for="(moduleList,index) in ecommerceList" :key="index">
        
          <!-- tab -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-if="currentType == 'tab' && moduleList.type == currentType">
            <tab :listData="moduleList"
                  @confirmSeckill="confirmSeckill"
                  @reset_tab_tap="reset_tab_tap">

            </tab>
          
          </div>
          <!-- diy1 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'diy1' && moduleList.type == currentType">
            <p class="textc fs9 mb20 fwbold">推荐模块</p>
            <div class="mb20 posre " v-for="(item,k) in moduleList.list" :key="k">
              <div class="" v-if="k == 0 || k == 2">
                <p class="fs8 fwbold mb10">{{k==0 ? '左侧模块' : '右侧模块'}}</p>
                <p class="fs8 mb10">模块名称</p>
                <el-input :placeholder="item.title" v-model="item.title" maxlength="4" show-word-limit class="mb10 w200 "></el-input>
              </div>
              <p class="fs8 mb10">图片{{k+1}}</p>
              <div class="bgf7fa pa10 d-flex align-items-center">
                
                <div class="d-flex flex-column align-items-center ">
                  <el-upload
                          accept="image/jpeg,image/png"
                          class="addTabIcon"
                          :action="uploadUrl"
                          :show-file-list="false"
                          :on-success="bannerPicSuccess.bind(null, {'type':currentType,'index':k,'data':item})"
                          :before-upload="beforeUploadImageDynamicPic">
                      <img v-if="item.img" :src="item.img" class="avatar dynamic ">
                      <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">建议上传{{item.size}}px的图片效果最佳</p>
                  </div>
                  <jumpSet title="123123123"
                          :index="currentIndex"
                          :currentSelect="k"
                          @currentSelect_tap="currentSelect_tap"
                          :currentGoods="item"
                          :goodsData="item"
                          @select_tap="select_tap"></jumpSet>
                </div>
                <div class="mt10" v-if=" k !== 2">
                  <p class="fs8 fwbold mb10">文案设置</p>
                  <el-input :placeholder="item.text" v-model="item.text" maxlength="4" show-word-limit class="mb10 w200 "></el-input>
                </div>
              </div>
              
          
          </div>
          <!-- 热销爆款 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'boutique' && moduleList.type == currentType">
            <specialGoods :listData="moduleList"
                          type="isOrdinary"
                          @confirmSeckill="confirmSeckill">
            </specialGoods>
          </div>

          <!-- 精选推荐 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'boutique1' && moduleList.type == currentType">
            <specialGoods :listData="moduleList"
                          type="isOrdinary"
                          @confirmSeckill="confirmSeckill">
            </specialGoods>
          </div>

        </div>
      </div>
      
      
        
    </div>

    </div>
  </div>
</template>

<script>
  import {
    saveShopModelConfig,
    queryShopModelConfig,
    updateShopModelConfig
  } from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import jumpSet from "./components/jumpSet";
import specialGoods from './numericalCode/specialGoods'
import tab from './numericalCode/tab'


export default {
  data() {
    return {
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      isUpdate:false,
      tabList:[
        {
          text: "名片",
          pagePath: "pages/index/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card_select.png"
        },
        {
          text: "媒体",
          pagePath: "pages/media/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/video.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video_select.png"
        },
        {
          text: "商城",
          pagePath: "pages/Products/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_select.png"
        },
        {
          text: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/browser.png",
          selectedIconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/browser_select.png"
        },
        {
          text: "官网",
          pagePath: "pages/WebSite/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/computer.png",
          selectedIconPath:this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer_select.png"
        },
      ],
      ecommerceList:[{
        img:require('../../../../src/static/icon_search.png'),
        type:'search'
      },{
        list:[{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab1.png',
          title:'手机'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab2.png',
          title:'平板'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab3.png',
          title:'电脑'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab4.png',
          title:'相机'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab5.png',
          title:'冰箱'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab6.png',
          title:'洗衣机'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab7.png',
          title:'厨卫电器'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab8.png',
          title:'家具'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab9.png',
          title:'电视机'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/tab10.png',
          title:'空调'
        }],
      
        type:'tab'
      },{
        type:'diy1',
        list:[{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/goodsA1.png',
          title:'超值优惠',
          goods:'', 
          size:'138x170',
          page:'',
          bg:'#2BCF88',
          text:'今天5折'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/goodsA2.png',
          goods:'', 
          size:'138x170',
          page:'',
          text:'拼团优惠'
        },{
          img:'https://one-stand-new.oss-cn-shanghai.aliyuncs.com/yimai_photos/numericalCode/goodsA3.png',
          goods:'', 
          page:'',
          title:'家庭必备',
          size:'335x170',
          bg:'#FFA133',
        }]
      },{
        type:'boutique',
        title:'热销爆款',
      
        list:[
          {
            goodsName:'华为手机',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB1.png'),
            tips:'穿上它，你就是最靓的仔',
            price:'1099900',
            maxPrice:'888800'
          },
          {
            goodsName:'精品相机',
            tips:'冷酷潮鞋，值得拥有轻便舒适，休闲运动',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB2.png'),
            price:'628800',
            maxPrice:'888800'
          },
          {
            goodsName:'苹果 11',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB3.png'),
            tips:'穿上它，你就是最靓的仔',
            price:'499900',
            maxPrice:'888800'
          },
          {
            goodsName:'小米 K40',
            tips:'冷酷潮鞋，值得拥有轻便舒适，休闲运动',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB4.png'),
            price:'499900',
            maxPrice:'888800'
          },
          {
            goodsName:'手机壳',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB5.png'),
            tips:'穿上它，你就是最靓的仔',
            price:'19900',
            maxPrice:'888800'
          },
          {
            goodsName:'手机手环',
            tips:'冷酷潮鞋，值得拥有轻便舒适，休闲运动',
            goodPhoto:require('../../../../src/static/numericalCode/goodsB6.png'),
            price:'88800',
            maxPrice:'888800'
          }
        ]
      },{
        type:'boutique1',
        title:'精品推荐',
        list:[
          {
            goodsName:'小米冰箱',
            goodPhoto:require('../../../../src/static/numericalCode/goodsC1.png'),
            tips:'穿上它，你就是最靓的仔',
            price:'88800',
            maxPrice:'888800'
          },
          {
            goodsName:'路由器',
            tips:'冷酷潮鞋，值得拥有轻便舒适，休闲运动',
            goodPhoto:require('../../../../src/static/numericalCode/goodsC2.png'),
            price:'88800',
            maxPrice:'888800'
          },
          {
            goodsName:'餐桌',
            goodPhoto:require('../../../../src/static/numericalCode/goodsC3.png'),
            tips:'穿上它，你就是最靓的仔',
            price:'88800',
            maxPrice:'888800'
          },
          {
            goodsName:'精品大床',
            tips:'冷酷潮鞋，值得拥有轻便舒适，休闲运动',
            goodPhoto:require('../../../../src/static/numericalCode/goodsC4.png'),
            price:'88800',
            maxPrice:'888800'
          }
        ]
      }],
      currentType:'',
      currentIndex:0,
      currentSelect:'',
      initializeData:"",
      requestWay:'save'
    };
  },
  components:{
    jumpSet,
    specialGoods,
    tab
  },
  created(){
    this.initializeData = JSON.parse(JSON.stringify(this.ecommerceList))
    console.log(this.initializeData)
    this.queryShopConfig()
  },
  mounted(){

  },
  methods:{
    queryShopConfig(){
      let data = {
        modelEnum:'digital_appliances',
      }
      queryShopModelConfig(data)
      .then(res=>{
        console.log(res)
        if(res.data.modelId){
          this.requestWay = 'up'
          this.ecommerceList = JSON.parse(res.data.shopDetails[0].content)
        }
      })
    },
    selected_tap(type){
      let index = ''
      this.ecommerceList.forEach((item,k) =>{
        if(item.type == type){
          this.currentIndex = k 
        }
      })
      console.log(this.currentIndex)
      this.currentType = type
    },
    bannerPicSuccess(obj,res,file){
      //动态图上传成功
      let than = this
      console.log(obj)
      this.ecommerceList.forEach((item,k) =>{
        if(item.type == obj.type){
          
          than.$set(than.ecommerceList[k].list[obj.index], 'img', this.ossUrl + '/' +res.data)
        }
      })
    },
    
    // 上移
    templateUp(){
      let arr = this.ecommerceList,
          index = this.currentIndex
      if(index !== 0){
        this.swapArray(arr,this.currentIndex,--this.currentIndex)
      }else{
        this.$message.error('已经处于置顶，无法上移')
      }
    },
    templateDown(){
      let arr = this.ecommerceList,
          length = this.ecommerceList.length,
          index = this.currentIndex
      if(index+1 != length){
        this.swapArray(arr,this.currentIndex,++this.currentIndex)
      }else{
        this.$message.error('已经触底，无法下移')
      }
    },
    templateDelete(){
      let index = this.currentIndex
      this.ecommerceList.splice(index,1)
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    beforeUploadImageDynamicPic(file){
      var _this = this;
      var isLt10M = file.size / 1024 / 1024  < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
          _this.$message.error('请上传正确的图片格式');
          return false;
      }
      if (!isLt10M) {
          _this.$message.error('上传图片大小不能超过10MB哦!');
          return false;
      }
    },
    currentSelect_tap(index){
      this.currentSelect = index
    },
    select_tap(index,val,type,goods,currentSelect){
      if(goods){
        if(currentSelect || currentSelect === 0){
          this.ecommerceList[this.currentIndex].list[currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[currentSelect].page
        }else{
          this.ecommerceList[this.currentIndex].list[this.currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[this.currentSelect].page
        }page
      }else{
        if(type){
          if(currentSelect || currentSelect === 0){
            this.ecommerceList[this.currentIndex].list[currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[currentSelect].goods
          }else{
            this.ecommerceList[this.currentIndex].list[this.currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[this.currentSelect].goods
          }
        }
        
      }
    },
    //重置数据
    initialize_tap(){
      this.ecommerceList = JSON.parse(JSON.stringify(this.initializeData))
    },
    //保存模板
    save_template(){
      let than = this
      let arr = JSON.parse(JSON.stringify(this.ecommerceList))
      try{
        
      }
      catch(e){
        if(e){
          return  than.$message.error('商城数据未完整');
        }
      }
      let p = new Promise((resolve,reject) => {
        arr.forEach((item,k)=>{
          if(item.list){
            item.list.forEach((v,k)=>{
              if(item.type == 'boutique' || item.type == 'boutique1'){
                if(!v.goodsId){
                  reject("精选模块未设置商品")
                }
              }
            })
          }
        })
          resolve('succ')
      })
      p.then(res => {
        console.log(res,'22222');
        let data = {
          modelEnum:'digital_appliances',
          shopDetails:[{
            content:JSON.stringify(arr)
          }]
        }
        if(than.requestWay == 'save'){
          saveShopModelConfig(data)
          .then(res=>{
            if(res.code == 200){
              than.$message.success("保存成功")
              setTimeout(()=>{
                than.queryShopConfig()
                than.$router.push('mallSetting')
              },500)
            }
          })
          .catch(err => {
            if(err.code == 201){
              this.$message.error(err.message)
            }
            console.log(err,'wwwww');
          })
        }else{
          updateShopModelConfig(data)
          .then(res=>{
            if(res.code == 200){
              than.$message.success("保存成功")
              setTimeout(()=>{
                than.queryShopConfig()
                than.$router.push('mallSetting')
              },500)
            }
          })
          .catch(err => {
            if(err.code == 201){
              this.$message.error(err.message)
            }
            console.log(err,'wwwww');
          })
        }
      })
      .catch(err => {
        this.$message.error(err)
      })
    },
    //重置tab
    reset_tab_tap(data){
      let than = this 
      than.ecommerceList.forEach((item,k)=>{
        if(item.type == 'tab'){
          than.$set(than.ecommerceList,k,data)
        }
      })
    },
    //确认秒杀商品
    confirmSeckill(index,data,type){
      console.log(index,data,type)
      let than = this
      than.ecommerceList.forEach((item,k)=>{
        if(item.type == type){           
          than.ecommerceList[k].list[index] = data
          than.$set(than.ecommerceList[k].list,index,data)
        }
      })
      
    }
  }
};
</script>

<style lang="scss" scoped>
.phoneClass{
  padding:50px 30px 0px 30px;
  background-image:url('../../../assets/images/iphonex.png') ;
  width:400px;
  height:800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
.group_title{
  background-image:url('../../../static/ecommerce/bg1.png');
  background-repeat:no-repeat;
  background-size: 100%;
}
.group_bgcolor{
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
}
.purchase{
  width: 74px;
  height: 32px;
  background: #FFA133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: 0px;
  top: 45px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}
.choicenessBgImg{
  bottom: 0px;
}
.choicenessBox{
  padding: 18px;
  width: 100p;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}
.fade_in{
  opacity: 1;
  z-index: 99;
  transition:all 0.2s;
}
.fade_out{
  opacity: 0;
  display: none !important;
  transition:all 0.2s;
}
.control{
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: #51CDCB;
  z-index: 99;
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  font-size: 18px;
}
 /deep/.addTabIcon .el-upload--text{
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}
.avatar{
  width: 100%;
  height: 100%;
}
.recommend{
  padding: 9px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  margin-bottom: 20px;
}
.diy1Bottom{
  position: absolute;
  width: 88%;
  height: 18px;
  line-height: 18px;
  color: #fff;
  font-size: 12px;
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
  border-radius: 0px 0px 5px 5px;
  bottom: 4px;
}
</style>